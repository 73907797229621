.mat-mdc-tab-labels {
  background-color: var(--tabs-background-color);
  border-radius: 100px;
  border-bottom-style: hidden !important;
  padding: 8px;
}

.mdc-tab-indicator {
  display: none !important;
}

.mdc-tab-indicator--active {
  background-color: var(--input-border-selected) !important;
  border-radius: 100px;
}

.mdc-tab__ripple::before {
  background-color: var(--input-border-selected) !important;
  border-radius: 100px;
}

.mat-mdc-tab-ripple {
  border-radius: 100px;
}

.mdc-tab__text-label {
  color: var(--tabs-color) !important;
}

.mdc-tab--active {
  .mdc-tab__text-label {
    color: #fff !important;
  }
}

.mat-mdc-tab-label-container {
  border-bottom-style: hidden !important;
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.3);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,0.4);
  }
}
