.expansion-panel {
  box-shadow: none !important;
  border-radius: 8px !important;
  .expansion-panel-header {
    height: 56px !important;
    padding: 0 16px;
    background-color: #242424 !important;
    color: #fff;
    text-transform: capitalize;
    .mat-content {
      margin: 0 !important;
    }
  }
  .mat-expanded {
    .expansion-panel-header {
      background-color: #2e372f !important;
    }
  }
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}
