.common-form-field {
  width: 100% !important;
  .mat-mdc-text-field-wrapper {
    background-color: var(--input-background);
  }
}

.custom-error {
  color: var(--mat-form-field-error-text-color);
}

.mat-mdc-form-field-hint-wrapper {
  text-wrap: nowrap;
  padding: 0px 0px !important;
}
