body {
  /* Toolbar  */
  --mat-toolbar-container-background-color: var(--primary);
  --mat-toolbar-container-text-color: var(--primary-light);

  /* Buttons  */
  --mdc-protected-button-label-text-color: var(--primary);
  --mat-protected-button-state-layer-color: var(--primary);
  --mdc-text-button-label-text-color: var(--primary);
  --mdc-filled-button-container-color: var(--primary);
  --mdc-outlined-button-label-text-color: var(--primary);
  --mat-text-button-state-layer-color: var(--primary);
  --mat-outlined-button-state-layer-color: var(--primary);
  --mdc-protected-button-container-color: var(--background);
  --mdc-filled-button-label-text-color: var(--primary-light);
  --mdc-outlined-button-outline-color: var(--primary-dark);
  --mdc-fab-container-color: var(--primary-light);
  --mdc-fab-small-container-color: var(--primary-light);
  --mat-outlined-button-ripple-color: var(--ripple);
  --mat-icon-button-ripple-color: var(--ripple);
  --mat-fab-ripple-color: var(--ripple);
  --mat-fab-small-ripple-color: var(--ripple);
  --mat-protected-button-ripple-color: var(--ripple);
  --mat-text-button-ripple-color: var(--ripple);
  --mat-fab-small-foreground-color: var(--primary-dark);
  --mat-fab-small-state-layer-color: var(--primary-dark);
  --mdc-icon-button-icon-color: var(--primary-dark);
  --mat-icon-button-state-layer-color: var(--primary-dark);
  --mat-fab-foreground-color: var(--primary-dark);
  --mat-fab-state-layer-color: var(--primary-dark);
  --mat-text-button-disabled-state-layer-color: var(--primary-dark);
  --mat-protected-button-disabled-state-layer-color: var(--primary-dark);
  --mat-filled-button-disabled-state-layer-color: var(--primary-dark);
  --mat-icon-button-disabled-state-layer-color: var(--primary-dark);

  /*Toggle */
  --mat-standard-button-toggle-selected-state-background-color: var(--input-background-selected);
  --mat-standard-button-toggle-background-color: var(--input-background);
  --mat-standard-button-toggle-divider-color: var(--primary-dark);
  --mat-standard-button-toggle-selected-state-text-color: var(--input-color-selected);
  --mat-standard-button-toggle-text-color: var(--primary-dark);
  --mat-standard-button-toggle-state-layer-color: var(--primary-dark);
  --mat-standard-button-toggle-disabled-state-background-color: var(--input-background);
  --mat-standard-button-toggle-disabled-state-text-color: var(--input-color-selected);
  --mat-standard-button-toggle-disabled-selected-state-background-color: var(--input-background-selected);

  /*Checkbox  */
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--primary);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary);
  --mdc-checkbox-selected-checkmark-color: var(--primary-light);
  --mdc-checkbox-unselected-focus-icon-color: var(--primary-dark);
  --mdc-checkbox-unselected-hover-icon-color: var(--primary-dark);
  --mdc-checkbox-unselected-icon-color: var(--primary-dark);
  --mdc-checkbox-unselected-pressed-icon-color: var(--primary-dark);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-dark);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--primary-dark);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--primary-dark);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--primary-dark);
  --mat-checkbox-label-text-color: var(--primary-dark);

  /* Progress  */
  --mdc-linear-progress-active-indicator-color: var(--input-border-selected);
  --mdc-circular-progress-active-indicator-color: var(--primary);
  --mdc-linear-progress-track-color: var(--primary-light);

  /*Text fields  */
  --mdc-filled-text-field-caret-color: var(--primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary);
  --mdc-filled-text-field-focus-label-text-color: var(--primary);
  --mdc-outlined-text-field-caret-color: var(--primary);
  --mdc-outlined-text-field-focus-outline-color: var(--primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary);
  --mdc-filled-text-field-disabled-label-text-color: var(--ripple);
  --mdc-filled-text-field-input-text-color: var(--primary-dark);
  --mdc-filled-text-field-disabled-input-text-color: var(--ripple);
  --mdc-filled-text-field-input-text-placeholder-color: var(--primary-dark);
  --mdc-filled-text-field-hover-active-indicator-color: var(--input-border);
  --mdc-filled-text-field-input-text-placeholder-color: var(--primary-dark);

  --mdc-outlined-text-field-label-text-color: var(--primary-dark);
  --mdc-outlined-text-field-hover-label-text-color: var(--primary-dark);
  --mdc-outlined-text-field-disabled-label-text-color: var(--primar-dark);
  --mdc-outlined-text-field-input-text-color: var(--primary-dark);
  --mdc-outlined-text-field-disabled-input-text-color: var(--primary-dark);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--primary-dark);

  --mdc-outlined-text-field-outline-color: var(--input-border);
  --mdc-outlined-text-field-disabled-outline-color: var(--ripple);
  --mdc-outlined-text-field-hover-outline-color: var(--primary-dark);
  --mat-form-field-trailing-icon-color: var(--primary-dark);

  --mdc-filled-text-field-error-hover-label-text-color: var(--error);
  --mdc-filled-text-field-error-focus-label-text-color: var(--error);
  --mdc-filled-text-field-error-label-text-color: var(--error);
  --mdc-filled-text-field-error-active-indicator-color: var(--error);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--error);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--error);
  --mat-form-field-error-text-color: var(--error);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--error);
  --mdc-outlined-text-field-error-label-text-color: var(--error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--error);
  --mdc-outlined-text-field-error-focus-outline-color: var(--error);
  --mdc-outlined-text-field-error-hover-outline-color: var(--error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--error);
  --mdc-outlined-text-field-error-outline-color: var(--error);
  --mat-form-field-error-focus-trailing-icon-color: var(--error);
  --mat-form-field-error-hover-trailing-icon-color: var(--error);
  --mat-form-field-error-trailing-icon-color: var(--error);
  --mdc-filled-text-field-container-color: var(--primary-light);
  --mdc-form-field-label-text-color: var(--primary-dark);
  --mdc-filled-text-field-label-text-color: var(--primary-dark);
  --mdc-filled-text-field-hover-label-text-color: var(--primary-dark);
  --mat-radio-disabled-label-color: #666;
  --mat-form-field-container-height: var(--input-height);

  /*Select*/
  --mat-form-field-focus-select-arrow-color: var(--primary);
  --mat-select-focused-arrow-color: var(--primary);
  --mat-select-enabled-trigger-text-color: var(--primary-dark);
  --mat-select-disabled-trigger-text-color: var(--primary);
  --mat-select-placeholder-text-color: var(--primary-dark);
  --mat-select-enabled-arrow-color: var(--primary-dark);
  --mat-option-selected-state-label-text-color: var(--selectedOption);
  --mat-option-label-text-color: var(--primary-dark);
  --mat-select-invalid-arrow-color: var(--error);
  --mat-option-hover-state-layer-color: var(--hover-primary);
  --mat-option-focus-state-layer-color: var(--hover-primary);
  --mat-select-panel-background-color: var(--primary-light);
  --mat-option-selected-state-layer-color: var(--input-border-selected) !important;

  /*Switch/toggle  */
  --mdc-switch-selected-focus-state-layer-color: var(--primary);
  --mdc-switch-selected-hover-state-layer-color: var(--primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--primary);
  --mdc-switch-selected-focus-track-color: var(--primary);
  --mdc-switch-selected-hover-track-color: var(--primary);
  --mdc-switch-selected-pressed-track-color: var(--primary);
  --mdc-switch-selected-track-color: var(--primary);
  --mdc-switch-unselected-pressed-track-color: var(--primary-light);
  --mdc-switch-unselected-track-color: var(--primary-light);
  --mat-switch-track-outline-color: var(--primary-dark);
  --mdc-switch-unselected-hover-track-color: var(--primary-light);
  --mdc-switch-unselected-icon-color: var(--primary-light);
  --mdc-switch-unselected-pressed-handle-color: var(--primary-dark);
  --mdc-switch-disabled-selected-icon-color: var(--primary-dark);
  --mdc-switch-disabled-selected-track-color: var(--primary-dark);
  --mdc-switch-disabled-unselected-handle-color: var(--primary-dark);
  --mdc-switch-disabled-unselected-icon-color: var(--primary-light);
  --mdc-switch-disabled-unselected-track-color: var(--primary-light);
  --mdc-switch-selected-icon-color: var(--primary-dark);
  --mdc-switch-unselected-focus-handle-color: var(--primary-dark);
  --mdc-switch-unselected-focus-state-layer-color: var(--primary-dark);
  --mdc-switch-unselected-focus-track-color: var(--primary-light);
  --mdc-switch-selected-focus-handle-color: var(--primary-light);
  --mdc-switch-selected-hover-handle-color: var(--primary-light);
  --mdc-switch-selected-pressed-handle-color: var(--primary-light);
  --mdc-switch-unselected-handle-color: var(--primary-dark);
  --mdc-switch-unselected-hover-handle-color: var(--primary-dark);
  --mdc-switch-unselected-hover-state-layer-color: var(--primary-dark);
  --mdc-switch-selected-handle-color: var(--primary-light);
  --mat-switch-label-text-color: var(--primary-dark);
  --mat-standard-button-toggle-disabled-selected-state-text-color: var(--input-color-selected);

  /*Radio*/
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
  --mat-radio-checked-ripple-color: var(--primary);
  --mdc-radio-disabled-selected-icon-color: var(--primary-dark);
  --mdc-radio-disabled-unselected-icon-color: var(--primary-dark);
  --mdc-radio-unselected-hover-icon-color: var(--primary-dark);
  --mdc-radio-unselected-icon-color: var(--primary-dark);
  --mdc-radio-unselected-pressed-icon-color: var(--primary-dark);
  --mat-radio-label-text-color: var(--primary-dark);

  /*Slider*/
  --mdc-slider-handle-color: var(--primary);
  --mdc-slider-focus-handle-color: var(--primary);
  --mdc-slider-hover-handle-color: var(--primary);
  --mdc-slider-active-track-color: var(--primary);
  --mdc-slider-label-container-color: var(--primary);
  --mat-slider-ripple-color: var(--primary);
  --mdc-slider-label-label-text-color: var(--primary-light);
  --mdc-slider-with-overlap-handle-outline-color: var(--primary-light);
  --mat-slider-hover-state-layer-color: var(--ripple);
  --mat-slider-focus-state-layer-color: var(--ripple);

  /*List*/
  --mdc-list-list-item-selected-trailing-icon-color: var(--primary);
  --mdc-list-list-item-leading-avatar-color: var(--primary-light);
  --mdc-list-list-item-disabled-state-layer-color: var(--primary-dark);
  --mdc-list-list-item-label-text-color: var(--primary-dark);
  --mdc-list-list-item-supporting-text-color: var(--primary-dark);
  --mdc-list-list-item-leading-icon-color: var(--primary-dark);
  --mdc-list-list-item-trailing-supporting-text-color: var(--primary-dark);
  --mdc-list-list-item-disabled-label-text-color: var(--primary-dark);
  --mdc-list-list-item-disabled-leading-icon-color: var(--primary-dark);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--primary-dark);
  --mdc-list-list-item-hover-label-text-color: var(--primary-dark);
  --mdc-list-list-item-focus-label-text-color: var(--primary-dark);
  --mdc-list-list-item-hover-state-layer-color: var(--primary-dark);
  --mdc-list-list-item-focus-state-layer-color: var(--primary-dark);
  --mat-list-active-indicator-color: var(--primary-light);

  /*Autocomplete */
  --mat-autocomplete-background-color: var(--primary-light);

  /*Expansion Panel */
  --mat-expansion-container-background-color: var(--background);
  --mat-expansion-container-text-color: var(--primary-dark);
  --mat-expansion-header-hover-state-layer-color: var(--ripple);
  --mat-expansion-header-focus-state-layer-color: var(--ripple);
  --mat-expansion-header-text-color: var(--primary-dark);
  --mat-expansion-header-description-color: var(--primary-dark);
  --mat-expansion-header-indicator-color: var(--primary-dark);

  /*Table/Paginator  */
  --mat-paginator-container-text-color: var(--primary-dark);
  --mat-paginator-container-background-color: var(--background);
  --mat-paginator-enabled-icon-color: var(--primary-dark);

  --mat-table-background-color: var(--background);
  --mat-table-header-headline-color: var(--primary-dark);
  --mat-table-row-item-label-text-color: var(--primary-dark);
  --mat-table-row-item-outline-color: var(--ripple);
  --mat-sort-arrow-color: var(--primary-dark);

  /*Card*/
  --mdc-elevated-card-container-color: var(--background);
  --mat-card-subtitle-text-color: var(--primary-dark);

  /*Menu */
  --mat-menu-container-color: var(--primary-light);
  --mat-menu-divider-color: var(--ripple);
  --mat-menu-item-label-text-color: var(--primary-dark);
  --mat-menu-item-icon-color: var(--primary-dark);
  --mat-menu-item-hover-state-layer-color: var(--ripple);
  --mat-menu-item-focus-state-layer-color: var(--ripple);

  /*Tabs */
  --mdc-tab-indicator-active-indicator-color: var(--primary);
  --mat-tab-header-active-focus-indicator-color: var(--primary);
  --mat-tab-header-active-hover-indicator-color: var(--primary);
  --mat-tab-header-pagination-icon-color: var(--primary-dark);
  --mat-tab-header-inactive-label-text-color: var(--primary-dark);
  --mat-tab-header-active-label-text-color: var(--primary-dark);
  --mat-tab-header-active-ripple-color: var(--primary-dark);
  --mat-tab-header-inactive-ripple-color: var(--primary-dark);
  --mat-tab-header-inactive-focus-label-text-color: var(--primary-dark);
  --mat-tab-header-inactive-hover-label-text-color: var(--primary-dark);
  --mat-tab-header-active-focus-label-text-color: var(--primary-dark);
  --mat-tab-header-active-hover-label-text-color: var(--primary-dark);
  --mdc-secondary-navigation-tab-container-height: 50px;

  /*Sidenav */
  --mat-sidenav-container-background-color: var(--background);
  --mat-sidenav-container-text-color: var(--primary-dark);
  --mat-sidenav-content-background-color: var(--background);
  --mat-sidenav-content-text-color: var(--primary-dark);

  /*Stepper */
  --mat-stepper-container-color: var(--background);
  --mat-stepper-line-color: var(--primary-light);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--primary-light);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--primary-light);
  --mat-stepper-header-icon-foreground-color: var(--primary-light);
  --mat-stepper-header-error-state-label-text-color: var(--error);
  --mat-stepper-header-error-state-icon-foreground-color: var(--error);
  --mat-stepper-header-hover-state-layer-color: var(--ripple);
  --mat-stepper-header-focus-state-layer-color: var(--ripple);
  --mat-stepper-header-label-text-color: var(--primary-dark);
  --mat-stepper-header-optional-label-text-color: var(--primary-dark);
  --mat-stepper-header-selected-state-label-text-color: var(--primary-dark);
  --mat-stepper-header-icon-background-color: var(--primary-dark);

  /*Datepickers  */
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--primary);
  --mat-datepicker-calendar-date-today-outline-color: var(--primary);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--primary);
  --mat-stepper-header-selected-state-icon-background-color: var(--primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--primary);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--ripple);
  --mat-datepicker-calendar-date-hover-state-background-color: var(-ripple);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--primary-light);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--primary-light);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: var(--primary-light);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: var(--ripple);
  --mat-datepicker-calendar-date-today-outline-color: var(--primary);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: var(--ripple);
  --mat-datepicker-calendar-date-disabled-state-text-color: var(-ripple);
  --mat-datepicker-calendar-date-selected-state-text-color: var(--primary-light);
  --mat-datepicker-calendar-container-background-color: var(--primary-light);
  --mat-datepicker-toggle-active-state-icon-color: var(--primary-dark);
  --mat-datepicker-toggle-icon-color: var(--primary-dark);
  --mat-datepicker-calendar-body-label-text-color: var(--primary-dark);
  --mat-datepicker-calendar-period-button-text-color: var(--primary-dark);
  --mat-datepicker-calendar-period-button-icon-color: var(--primary-dark);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--primary-dark);
  --mat-datepicker-calendar-header-text-color: var(--primary-dark);
  --mat-datepicker-calendar-date-text-color: var(--primary-dark);
  --mat-datepicker-range-input-separator-color: var(--primary-dark);

  /*Snackbar  */
  --mat-snack-bar-button-color: white;
  --mdc-snackbar-container-color: #2E372FE5;

  /*Chips*/
  --mdc-chip-outline-color: var(--primary-dark);
  --mdc-chip-focus-outline-color: var(--primary-dark);
  --mdc-chip-focus-state-layer-color: var(--primary-dark);
  --mdc-chip-hover-state-layer-color: var(--primary-dark);
  --mdc-chip-selected-hover-state-layer-color: var(--primary-dark);
  --mdc-chip-selected-focus-state-layer-color: var(--primary-dark);
  --mdc-chip-label-text-color: var(--primary-dark);
  --mdc-chip-selected-label-text-color: var(--primary-dark);
  --mdc-chip-with-icon-icon-color: var(--primary-dark);
  --mdc-chip-with-icon-disabled-icon-color: var(--primary-dark);
  --mdc-chip-with-icon-selected-icon-color: var(--primary-dark);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--primary-dark);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--primary-dark);

  /* Dialogs  */
  --mdc-dialog-container-color: var(--background);
  --mdc-dialog-subhead-color: var(--primary-dark);
  --mdc-dialog-supporting-text-color: var(--primary-dark);

  /*Background */
  --mat-app-background-color: var(--background);

  /*Text*/
  --mat-app-text-color: var(--primary-dark);

  /*Ripple*/
  --mat-ripple-color: var(--ripple);

  /*Divider*/
  --mat-divider-color: var(--input-border);

  /* Bottom Sheet */
  --mat-bottom-sheet-container-background-color: var(--tabs-background-color);
}
