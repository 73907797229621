.main-button {
  background-color: #2BC954 !important;
  color: #fff !important;
  font-weight: 500;
}

.main-button-disabled {
  background-color: #CCFFD8 !important;
}

.option {
  border: 1px solid var(--input-border) !important;
  border-radius: 5px !important;
}

.mat-button-toggle-checked {
  border: 1px solid var(--input-border-selected) !important;
  color: #fff !important;
  font-weight: 500;

  .mat-pseudo-checkbox:after{
    color: #fff !important;
  }
}

.danger-button {
  background-color: var(--button-danger-color-background) !important;
  color: var(--button-danger-color) !important;
  border: 1px solid var(--button-danger-border-color) !important;

  &:hover {
    background-color: var(--button-danger-color-hover) !important;
  }
}

.secondary-button {
  background-color: var(--secondary-button-background) !important;
  color: var(--secondary-button-color) !important;
  border: 1px solid var(--secondary-button-border-color) !important;

  &:hover {
    background-color: var(--secondary-button-color-hover) !important;
  }
}

.error-button {
  border: 1px solid var(--error) !important;
}

.active-button{
  border-color: #2bc954 !important;
}
