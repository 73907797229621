.mat-mdc-header-row {
  border: 1px solid var(--table-border) !important;
  background-color: var(--table-header-background) !important;
  font-weight: 600;
}

.mat-mdc-table {
  border: 1px solid var(--table-border) !important;
  border-radius: 6px;
}

.mat-mdc-cell {
  border-bottom-color: var(--table-border) !important;
}

.mat-mdc-header-cell {
  border-bottom-color: var(--table-border) !important;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
  background: unset !important;
}

.mdc-data-table__cell {
  padding: 6px 2px !important;
}