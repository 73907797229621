// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import './../m3-theme.scss';

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($light-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.

// Comment out the line below if you want to use the deprecated `color` inputs.
/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: var(--primary);
  background-color: var(--background);
}

@import './easy-theming.css';

.flex-row {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.mat-mdc-snack-bar-handset {
  position: static !important;
  margin-top: 48px !important;
}

.disabled-selected {
  background-color: #2bc954 !important;
}

.no-border {
  border: 0 !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin {
  -webkit-animation: rotating .5s linear infinite;
  -moz-animation: rotating .5s linear infinite;
  -ms-animation: rotating .5s linear infinite;
  -o-animation: rotating .5s linear infinite;
  animation: rotating .5s linear infinite;
}

@import './app/styles/override-button.scss';
@import './app/styles/override-form-field.scss';
@import './app/styles/override-expansion-panel.scss';
@import './app/styles/override-typography.scss';
@import './app/styles/override-dropdown.scss';
@import './app/styles/override-tabs.scss';
@import './app/styles/override-bottom-sheet.scss';
@import './app/styles/override-table.scss';
@import './app/styles/override-dialog.scss';
